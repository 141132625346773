import { ReactNode, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  onImpressed?(): void;
  onChange?(isVisible: boolean): void;
  partialVisibility?: boolean;
  children: ReactNode;
}

const Impressionable = (props: Props) => {
  const { children, onImpressed = () => {}, onChange, partialVisibility } = props;

  const [isImpressed, setIsImpressed] = useState(false);

  const handleChange = (isVisible: boolean) => {
    if (isVisible && !isImpressed) {
      onImpressed();
      setIsImpressed(true);
    }

    if (onChange) {
      onChange(isVisible);
    }
  };

  const { ref } = useInView({
    onChange: handleChange,
    threshold: partialVisibility ? 0.2 : 1, // Adjust based on `partialVisibility` (20% or full)
  });

  return (
    <div data-marker="Impressionable" ref={ref}>
      {children}
    </div>
  );
};

export default Impressionable;
