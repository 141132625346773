import css from 'styled-jsx/css';
import { desktop, phone, tablet_md_desktop } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  .ProductTile {
    position: relative;
    ${typography.text}
    text-decoration: none;
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 8px;
    align-items: stretch;
    cursor: pointer;
    display: block;
    transition: opacity 0.3s;
  }
  .ProductTile {
    height: 100%;
  }
  .ProductTile_slider {
    margin-bottom: 1px; /* fix pruning of border-bottom in FF */
  }
  .ProductTile_withOpacity,
  .ProductTile_tintingMode {
    opacity: 0.5;
  }
  .ProductTile:hover .ProductTile__footer {
    display: block;
  }
  .ProductTile__badges {
    position: absolute;
    top: 12px;
    left: 0;
  }
  .ProductTile__favouritesControl {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .ProductTile__imageContainer {
    position: relative;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: 188px;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    flex-grow: 1;
    min-height: 0;
  }
  .ProductTile__image_unavailable {
    opacity: 0.5;
  }
  .ProductTile__footer {
    cursor: pointer;
  }
  .ProductTile__titleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75px;
    position: relative;
    width: 100%;
  }
  .ProductTile__title {
    width: 100%;
    text-decoration: none;
    position: relative;
    display: block;
    color: var(--colors-site);
    line-height: 18px;
    max-height: 54px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: -o-ellipsis-lastline;
  }
  .ProductTile__title:hover {
    color: var(--uiColors-ProductTile-titleHoverText);
  }
  .ProductTile__titleEllipsis {
    width: 60px;
    background-color: #fff;
  }
  .ProductTile__oldPrice {
    margin-bottom: 3px;
  }
  .ProductTile__weight {
    color: var(--colors-doveGray);
    line-height: normal;
  }
  .ProductTile__prices {
    margin-bottom: 8px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
  }
  .ProductTile__priceWholesales {
    ${typography.h9}
    color: var(--colors-text);
  }
  .ProductTile__unavailable {
    margin-bottom: 10px;
    ${typography.h9}
    color: var(--colors-darkenGrey1);
  }
  .ProductTile__footer {
    background: linear-gradient(
      180deg,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0.39) 13.66%,
      rgba(255,255,255,0.66) 27.11%,
      rgba(255,255,255,0.89) 43.8%,
      rgba(255,255,255,0.981) 59.94%,
      #fff 78.78%);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .ProductTile__quantityBox {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
  }
  .ProductTile__details {
    text-align: left;
  }
  @media ${desktop} {
    .ProductTile__imageContainer {
      padding-bottom: 0;
    }
    .ProductTile__footer {
      height: 80px;
      display: none;
    }
    .ProductTile__footer_active {
      display: block;
    }
  }
  @media ${tablet_md_desktop}   {
    .ProductTile__footer {
      height: 70px;
      display: block;
    }
  }
  @media ${phone} {
    .ProductTile__imageContainer {
      height: 158px;
    }
    .ProductTile__favouritesControl {
      top: 5px;
      right: 3px;
    }
    .ProductTile__titleWrapper {
      height: 75px;
    }
    .ProductTile__weight {
      bottom: -1px;
      line-height: 15px;
    }
    .ProductTile__footer {
      height: 70px;
      width: 100%;
    }
  }
  `;
